// export const myGetter = (state) => {
//  return state
// }
export const getEntriesByTerm = (state) => (term = '') => {
    if (term.length === 0) return state.entries
    return state.entries.filter(entry => entry.text.toLowerCase().includes(term.toLocaleLowerCase()))
}
export const getEntriesById = (state) => (id = '') => {
    const entry = state.entries.find(entry => entry.id === id)
    //se usa la función find para buscar dentro del siguiente metodo
    //pregunta si el entry.is es igual al id que se recibe como argumento
    if(!entry) return
    return {...entry}
}