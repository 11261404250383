// export const myAction =  (state) => {

// }
export const setEntries = (state, entries) => {
    //Se desescrutura los entries actuales y se esparcen las nuevas entradas
    //Se crea un arreglo con los valores anteriores y los nuevos valores
    state.entries = [...state.entries, ...entries]
    //también se puede hacer el cambio del isLoading
    state.isLoading = false
}
export const updateEntry = (state, entry) => {
    //Se hace un arreglo con todos los id y retorna el indice del del objeto de ese id 
    const idx = state.entries.map( e => e.id ).indexOf( entry.id )
    state.entries[idx] = entry
}
export const addEntry = (state, entry) => {
    state.entries = [ entry, ...state.entries  ]
}
export const deleteEntry = (state, id) => {
    state.entries = state.entries.filter(entry => entry.id !== id)
    //Se redenriza el nuevo arreglo de entradas aplicando un filtro el cual excluye el id que se entrega como argumento y no la muestra
}